import withWidth from '@material-ui/core/withWidth';
import React from 'react';

import AccountPicker from '../../components/account-picker';
import AuthLayout from '../../components/auth-layout';
import { useTranslation } from '../../hooks/useTranslation';
interface Props {
  path?: string;
  redirectUrl?: string;
}
const RegistrationPage = (props: Props) => {
  const { t, richT } = useTranslation();
  const { redirectUrl } = props;

  const title = t('REGISTRATION_HEADER');
  const subtitle = richT('REGISTER_TO_VIEW_YOUR_ENERGY_USE');

  return (
    <AuthLayout
      title={title}
      subtitle={subtitle}
      footer={<div />}
      showLeft={false}
    >
      <AccountPicker redirectUrl={redirectUrl} />
    </AuthLayout>
  );
};

export default withWidth()(RegistrationPage);
