import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import Button from '../buttons';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  cardContent: {
    padding: 0,
    '&:first-child': {
      marginBottom: 24,
    },
  },
  card: {
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingBottom: 0,
    alignItems: 'flex-start',
    width: '100%',
  },
  textPrimary: {
    fontSize: 20,
    fontWeight: 300,
    color: colors.noirBlur,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 20px',
    },
    width: '100%',
  },
  button: {
    flexGrow: 0,
    minHeight: theme.spacing(6),
    minWidth: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      '&:first-child': {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    fontSize: 18,
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
    },
  },
}));

interface Props {
  redirectUrl?: string;
}
const AccountPicker = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { redirectUrl } = props;

  return (
    <Paper className={classes.paper}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.textPrimary}
            component={'span'}
            color={'textPrimary'}
            variant={'body1'}
          >
            {t('ACCOUNT_PICKER_TEXT')}
          </Typography>
        </CardContent>
        <CardContent
          className={`${classes.buttonContainer} ${classes.cardContent}`}
        >
          <Button
            data-testid={'create-residential-button'}
            className={classes.button}
            variant={'outlined'}
            color={'secondary'}
            onClick={() =>
              navigate(ROUTES.REGISTRATION_CREATE_RESIDENTIAL, {
                state: { redirectUrl: redirectUrl },
              })
            }
          >
            {t('RESIDENTIAL')}
          </Button>
          <Button
            data-testid={'create-business-button'}
            className={classes.button}
            variant={'outlined'}
            color={'secondary'}
            onClick={() =>
              navigate(ROUTES.REGISTRATION_CREATE_BUSINESS, {
                state: { redirectUrl: redirectUrl },
              })
            }
          >
            {t('BUSINESS')}
          </Button>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default AccountPicker;
